const translations = {
    en: {
        more_info: "More information",
        max_amount: "The maximum amount is ",
        min_amount: "The minimum amount is ",
        card_number_label: "Card number",
        month_card_label: "MM/YY",
        pay_text: "Pay ",
        due_label:"Due ",
        dues_label: "Dues ",
        phone_number_label: "Phone number",
        select_bank_label: "Select Bank",
        select_label: "Select",
        error_getting_bank_list: "There was a problem getting the list of banks",
        cobru_created_error: "Cobru could not be created",
        payment_method_not_enbled_error: "Payment method is not enabled.",
        unexpected_error:"There was an unexpected error",
        error_getting_crypto_value:"There was a problem obtaining the crypto value",
        daviplata_status_error:"Error getting Daviplata status",
        efecty_point_info:"Mention this information when you are at an Efecty point",
        corresponsal_point_info:"Mention this information when you are at an Corresponsal Bancolombia point",
        pay_with: "Pay with ",
        name_error: "Please enter a valid name",
        document_number_error: "Invalid document number",
        email_error: "Invalid email",
        phone_number_error: "Invalid phone number",
        cobru_not_exist_error:"The Cobru does not exist",
        choose_payment_method_label: 'Choose your payment method',
        calculating_text:"Calculating",
        full_name_label:"Full name",
        email_label:"Email",
        loading:"Loading",
        id_number: 'ID number',
        scan_nequi_text:"Scan with Nequi.",
        pay_with_qr: "Pay with QR",
        project_label:'PROYECTO',
        agreement_label: "AGREEMENT",
        reference_label: "REFERENCE",
        successful_payment: 'Successful payment',
        authorization: "Authorization",
        date_and_time: "Date and Time",
        fee: "Fee",
        fee_iva: 'Fee IVA',
        amount:'Amount',
        nequi_notification_center_text:"Check Nequi notification center",
        terms_and_conditions:"Terms and Conditions",
        privacy_policy:"Privacy Policy",
        how_do_you_want_to_pay:"How do you want to pay?"


    },
    es: {
        more_info:"Más información",
        max_amount: "El monto máximo es ",
        min_amount:"El monto mínimo es ",
        card_number_label: "Número de la tarjeta",
        month_card_label: "MM/AA",
        pay_text: "Pagar ",
        due_label:"Cuota ",
        dues_label: "cuotas ",
        phone_number_label: "Número de celular",
        select_bank_label: "Seleccionar Banco",
        select_label: "Seleccionar",
        error_getting_bank_list: "Hubo un problema obteniendo la lista de bancos",
        cobru_created_error: "No se pudo crear el cobru",
        payment_method_not_enbled_error: "El método de pago no está habilitado.",
        unexpected_error:"Hubo un error inesperado",
        error_getting_crypto_value:"Hubo un problema obteniendo el valor crypto",
        daviplata_status_error:"Error obteniendo el status de Daviplata",
        efecty_point_info:"Menciona estos datos cuando estés en un punto Efecty",
        corresponsal_point_info:"Menciona estos datos cuando estés en un punto Corresponsal Bancolombia",
        pay_with: "Pagar con ",
        name_error: "Ingresa un nombre válido",
        document_number_error: "Número de documento inválido",
        email_error: "Correo electrónico inválido",
        phone_number_error: "Número de celular inválido",
        cobru_not_exist_error:"El cobru no existe",
        choose_payment_method_label: 'Escoge tu medio de pago',
        calculating_text:"Calculando",
        full_name_label:"Nombre completo",
        email_label:"Correo electrónico",
        loading:"Cargando",
        id_number: 'Número de identificación',
        scan_nequi_text:"Escanea con Nequi.",
        pay_with_qr: "Paga con QR",
        project_label:'PROYECTO',
        agreement_label: "CONVENIO",
        reference_label: "REFERENCIA",
        successful_payment: 'Pago exitoso',
        authorization: "Autorización",
        date_and_time: "Fecha y hora",
        fee: "Comisión",
        fee_iva: 'IVA Comisión',
        amount:'Monto',
        nequi_notification_center_text:"Revisa centro de notificación Nequi",
        terms_and_conditions:"Términos y condiciones",
        privacy_policy:"Política de privacidad",
        how_do_you_want_to_pay:"¿Cómo quieres pagar?"



    }
}
export default translations;
