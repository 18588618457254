import React, { useEffect, useState } from 'react'
import cobruLogo from '../assets/icons/cobruIconHorizontal.png'
import ezyLogo from '../assets/icons/EzyLogo.jpeg'
import pagaHoyLogo from '../assets/icons/paga-hoy-horizontal.png'

const NavBarCobru = () => {
    const isEzy = window?.location?.hostname === 'link.ezytransfer.co' ? true : false
    const isPagaHoy = window?.location?.hostname === 'link.pagahoy.com' ? true : false

    const [showNav, setShowNav] = useState('')

    const handleScroll = () => {
        const scroll = window.scrollY;

        if (scroll > 10) {
            setShowNav("show");
        } else {
            setShowNav("");
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])
    return (
        <nav className={showNav}>
            <div className="wrapper">
                <a className="flex justify-center items-center" href="https://cobru.co/">
                    <img src={isEzy ? ezyLogo : isPagaHoy ? pagaHoyLogo : cobruLogo} />
                </a>
            </div>
        </nav>
    )
}

export default NavBarCobru